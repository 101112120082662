@import '../index.scss';

.create{
    padding: 40px;
    h1{
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 600;
        font-style: italic;
        color: white;
    }

    &__filter{
        padding: 20px 0 40px 0;
        display: flex;
        align-items: center;
        gap: 12px;
        select{
            background-color: transparent;
            outline: none;
            border-radius: 3px;
            padding: 6px ;
            border: solid 1px $blackborder;

            &:focus{
                border: solid 1px $green;
            }
        }
    }
    table{
        width: 100%;
        margin: auto;
        padding: 50px 0;
        border-collapse: collapse;

        tr{
            background-color: $blacklight;
            border-collapse: 0px;
            &:nth-child(odd) {
                background-color: $blackborder;  // Remplacez par la couleur de fond désirée
            }

            td{
                padding: 8px 12px 8px 12px;

                img{
                    margin: auto;
                }
                button{
                    background-color: $green;
                    border: none;
                    cursor: pointer;
                    padding: 6px 12px ;
                    border-radius: 2px;
                    font-weight: 600;
                    transition: all 200ms linear;
                    background-color: $green;
                    color: $black;

                    &:hover{
                        background-color: $greenHover;
                    }
                }
            }
        }
    }
}