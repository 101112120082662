@import '../index.scss';

.join{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    height: 100vh;

    h1{
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 600;
        font-style: italic;
        color: white;
    }

    form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;

        label{
            display: none;
        }
        input{
            background-color: transparent;
            border: solid 1px $blackborder;
            padding: 12px 12px;
            font-size: 18px;
            outline: none;
            border-radius: 5px;
            width: 400px;

            &:focus{
                border: solid 1px $green;
            }
        }

        button{
            background-color: $green;
            border: none;
            cursor: pointer;
            padding: 12px 12px;
            border-radius: 5px;
            font-weight: 600;
            transition: all 200ms linear;
            background-color: $green;
            width: 400px;
            font-size: 18px;
            color: $black;

            &:hover{
                background-color: $greenHover;
            }
        }
    }
}