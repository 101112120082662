//FONT
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//VARIABLE
$black : #1A1A1A;
$blacklight : #202020;
$blackborder : #2E2E2E;
$green: #90fd13;
$greenHover: #76d707;
$error: #ff3535;
$white: #E2E2E2;
$grey: #737373;


//GENERAL
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  color: $white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body{
  width: 100%;
  min-height: 100vh;
  background-color: $black;
}

p,h1,h2,h3,h3{
  margin:0;
  font-weight: 400;
}

.loader_Container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .loader {
    width: 30px;
    height: 30px;
    border: 3px solid $green;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  p{
    color: $grey
  }
  
  @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
  } 
}

