@import '../index.scss';

.game{
    background-color: $black;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    &__topbar{
        width: 100%;
        padding: 40px 40px 0px 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__code{
            text-transform: uppercase;
            color: $grey;
            text-align: center;
            a{
                color: $green;
                text-decoration: none;
            }
        }

        &__playerA, &__playerB{
            background-color: $blacklight;
            width: 250px;
            padding: 20px;
            border: solid $blackborder 1px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .lifeCounter{
                display: flex;
                align-items: center;
                gap: 12px;

                &__life{
                    width: 16px;
                    aspect-ratio: 1/1;
                    background-color: $blackborder;
                    border-radius: 100%;
                }

                .active{
                    background-color: $green;
                }
            }
        }
        &__playerB{
            flex-direction: row-reverse;
        }

        .active{
            border: solid $green 1px;
        }
    }

    &__title{
        h2{
            font-size: 36px;
            text-transform: uppercase;
            font-weight: 600;
            font-style: italic;
            color: white;

            span{
                font-size: 24px;
                color: $green;
                margin: 0px 30px;
                padding: 5px 20px;
                border-radius: 50px;
                border: 3px solid $green;
            }
        }
        p{
            text-align: center;
            font-weight: 600;
            font-style: italic;
            color: $grey;
        }
    }

    &__main{
        height: 100%;
        width: 100%;
        padding: 0px 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &__center{
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            gap: 50px;
            width: 400px;
            img{
                width: 50%
            }
        }
        &__team{
            background-color: $blacklight;
            border: $blackborder 1px solid;
            border-radius: 10px;
            padding: 24px 12px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 200px;
            span{
                font-weight: 500;
                text-transform: uppercase;
            }

            &__title{
                text-decoration: underline;
                color: $grey;
            }
        }
    }
    &__footer{
        width: 100%;
        padding: 0px 0px 60px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        &__guess{

            div{

                display: flex;
                align-items: center;
                justify-content: center;
                height: 70px;
                width: 500px;
                border-radius: 5px;
                overflow: hidden;
    
                input{
                    height: 100%;
                    width: 100%;
                    padding: 0px 20px;
                    border: none;
                    outline: none;
                    background-color: $blacklight;
                    border: solid $blackborder 1px;
                    font-size: 18px;
                }
    
                button{
                    height: 100%;
                    padding: 0px 40px;
                    background-color: $green;
                    border: none;
                    font-size: 18px;
                    cursor: pointer;
                    transition: all 200ms linear;
                    color: $black;
    
                    &:hover{
                        background-color: $greenHover;
                    }
                }
            }
        }

        &__end{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 80%;
            text-align: center;
            p{
                font-size: 24px;
                width: 100%;
                font-weight: 600;
            }
            a{
                color: $green;
                text-decoration: none;
            }
        }

        &__text{
            font-size: 20px;
            font-weight: 600;
            text-transform: uppercase;
            color: $grey;

            .green{
                color: $green;
                font-weight: 600;
            }

            .error{
                color: $error;
                font-weight: 600;
            }
        }
    }
}

.gameEnd{
    
    
}