@import '../index.scss';

.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
    height: 100vh;
    background: url('https://images.unsplash.com/photo-1517747614396-d21a78b850e8?q=80&w=2225&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center/cover,
              rgba(0, 0, 0, 0.8); /* Couleur de superposition */
    background-blend-mode: darken;

    h1{
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 600;
        font-style: italic;
        color: white;
    }

    p{
        width: 700px;
        text-align: center;
        color: $grey;
    }

    a{
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        background-color: $green;
        border: none;
        cursor: pointer;
        padding: 12px 12px;
        border-radius: 5px;
        font-weight: 600;
        transition: all 200ms linear;
        background-color: $green;
        width: 400px;
        font-size: 18px;
        color: $black;

        &:hover{
            background-color: $greenHover;
        }
    }
}